@import 'src/styles/_typography.scss';

.mat-button-base {
  &.mat-button {
    border-radius: 12px;
    backdrop-filter: blur(20px);
    font-weight: 600;
    @extend .f-14;
    padding: 14px 24px;
    line-height: 1;

    &.icon {
      span {
        display: flex;
        align-items: center;
        gap: 5px;
      }

      i {
        @extend .f-12;
      }
    }

    &.primary {
      background-color: var(--secondary-bg);
      color: var(--main-color);

      &:before {
        content: '';
        position: absolute;
        z-index: -1;
        inset: 0;
        padding: 2px;
        border-radius: 12px;
        background: linear-gradient(
          93.21deg,
          #404040 0%,
          rgba(255, 255, 255, 0) 53.23%,
          rgba(255, 255, 255, 0.45) 103.62%
        );
        mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        mask-composite: exclude;
      }
    }

    &.delet {
      background-color: var(--red-color);
      color: var(--main-color);

      &:before {
        content: '';
        position: absolute;
        z-index: -1;
        inset: 0;
        padding: 2px;
        border-radius: 12px;
        background: linear-gradient(
          93.21deg,
          #404040 0%,
          rgba(255, 255, 255, 0) 53.23%,
          rgba(255, 255, 255, 0.45) 103.62%
        );
        mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        mask-composite: exclude;
      }
    }

    &.logout {
      background-color: transparent;
      color: var(--main-color);
      font-weight: 600;
    }

    &.mat-button-disabled,
    &:disabled {
      color: var(--primary-bg) !important;
      opacity: 0.5;
    }
  }
}

.mat-checkbox {
  border-color: var(--text-color);
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent
  .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent
  .mat-ripple-element {
  background-color: var(--text-color);
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: transparent;
}

.mat-checkbox-checkmark-path {
  stroke: var(--text-color) !important;
}

.mat-checkbox-frame {
  border-color: var(--text-color);
}
