.card-tablet {
  background: var(--tablet-bg);
  border: var(--border);
  border-radius: 12px;
  padding: 0 24px 6px;
  border: 0.5px solid var(--Button, #404040);
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(20px);

  table {
    width: 100%;

    &.mat-table {
      background: transparent;
    }

    th.mat-header-cell {
      width: auto;
      min-width: max-content;
    }
    td.mat-cell {
      width: auto;
      min-width: max-content;
    }

    th.mat-header-cell {
      height: 45px;
      color: var(--text-color);
      @extend .f-12;
      font-weight: 600;
    }

    th.mat-header-cell,
    td.mat-cell {
      border-bottom-color: #404040;
    }

    tr.mat-row {
      height: 40px;

      td.mat-cell {
        @extend .f-14;
        font-weight: 500;
        color: var(--main-color);
      }

      &:last-of-type {
        td.mat-cell {
          border-bottom-color: transparent;
        }
      }
    }
  }
}
