:root {
  font-size: calc(16px + 0.3125vw);
  --ts: all 0.3s ease-in-out;
  --br: 0.5rem;
  --time-c-b: cubic-bezier(0.25, 0.46, 0.45, 0.94);

  --text-color: #5ec3d7;
  --main-color: #fff;
  --primary-bg: #0e1011;
  --secondary-bg: #ffffff1a;
  --border: 2px solid rgba(255, 255, 255, 0.35);
  --tablet-bg: rgba(255, 255, 255, 0.1);

  --yellow-color: #cc4a01;
  --green-color: #1cb12b;
  --red-color: #b11c1c;
  --red-color2: #BF1E1E,
  --purple-color: #5e71d7;

  --skeleton-bg: #e9e9e9;
  --skeleton-bg-dark: #d9d9d9;
  --max-width: 1500px;

  // ? Navbar variables
  --navbar-max-width: 1600px;
  --navbar-logo-height: 40px;
  --navbar-position: relative;

  --navbar-top-bg: #272829; // transparent
  --navbar-top-color: #808080;
  --navbar-top-padding: 15px 2% 15px 2%;

  --navbar-scroll-bg: rgba(255, 255, 255, 0.1);
  --navbar-scroll-color: #808080;
  --navbar-scroll-padding: 0 64px;
}
